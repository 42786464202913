<template>
  <!-- 数据展示 -->
  <div class="original-item">
    <!-- 左边 -->
    <div class="left">
      <div class="index">{{ index + 1 }}</div>
      <div class="data" v-if="!isdelete">{{ long.original_url }}</div>
      <el-input v-model="url" style="width: 440px" placeholder="输入新长链" v-else />
    </div>
    <!-- 右边 -->
    <div class="right">
      <div class="note" v-if="!isdelete">{{ long.note }}</div>
      <el-input v-model="note" style="width: 300px" placeholder="输入新备注" v-else />
      <div class="count">{{ long.click_count }}</div>
      <div class="action">
        <!-- 修改按钮 -->
        <el-button type="warning" v-if="!isdelete" @click="isdelete = !isdelete">修改</el-button>
        <el-button type="primary" v-else @click="updateoriginal(long)">保存</el-button>
        <!-- 删除按钮 -->
        <el-popconfirm title="确定删除改长链吗?" @confirm="deleteoriginal(long.id,long.short_code)" v-if="!isdelete">
            <template #reference>
                <el-button type="danger">删除</el-button>
            </template>
        </el-popconfirm>
        <el-button type="danger" v-else @click="isdelete = !isdelete">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus'

export default {
  props: ["index", "long", 'readshortlinks'],
  data(){
    return{
        isdelete:false,
        url:'',
        note:''
    }
  },
  mounted(){
    this.url = this.long.original_url
    this.note = this.long.note
  },
  methods:{
    // 删除长链
    deleteoriginal(id,code){
        axios.post("https://api.cxkqun.com/index.php/api/short/long",{
            'auth':'cxkqun',
            'action':'delete',
            'shortCode':code,
            'id':id
        }).then(res => {
            if(res.data.code){
                ElMessage({
                    message: '长链删除成功',
                    type: 'success',
                })
                this.readshortlinks()
            }else{
                ElMessage({
                    message: '长链删除失败',
                    type: 'error',
                })
            }
        })
    },
    // 修改长链
    updateoriginal(long){
        if(!this.isValidURL(this.url)){
            ElMessage({
                message: '请输入正确的url',
                type: 'error',
            })
        }else{
            axios.post("https://api.cxkqun.com/index.php/api/short/long",{
                'auth':'cxkqun',
                'action':'update',
                'shortCode':long.short_code,
                'id':long.id,
                'newUrl':this.url,
                'note':this.note
            }).then(res => {
                if(res.data.code){
                    ElMessage({
                        message: '长链修改成功',
                        type: 'success',
                    })
                    this.readshortlinks()
                }else{
                    ElMessage({
                        message: '长链修改失败',
                        type: 'error',
                    })
                }
                this.isdelete = !this.isdelete
            })
        }
    },
    // url格式检查
    isValidURL(string) {
        let url;
        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
  }
};
</script>

<style scoped lang="less">
// 数据
.original-item {
  border-top: solid #b1b3b8 1px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  & > .left {
    display: flex;
    align-items: center;
    & > .index {
      width: 60px;
      padding: 0 10px 0 10px;
      text-align: left;
    }
    & > .data {
      padding-left: 10px;
    }
  }
  & > .right {
    display: flex;
    align-items: center;
    &>.note{
        width: 300px;
        text-align: left;
    }
    & > .count {
      width: 60px;
    }
    & > .action {
      width: 150px;
      display: flex;
      justify-content: center;
    }
  }
}
</style>