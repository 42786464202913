<template>
  <CxkQun></CxkQun>
  <!-- <MyDemo></MyDemo> -->
</template>

<script>
import CxkQun from './components/CxkQun.vue';
// import MyDemo from './components/MyDemo.vue';

export default {
  name: 'App',
  components: {
    CxkQun,
    // MyDemo
  }
}
</script>

<style>
  *{
    margin: 0;
    box-sizing: border-box;
  }
</style>
