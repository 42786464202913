<template>
    <div class="add-original">
        <el-input v-model="originalinput" style="width: 600px" placeholder="请以https://或http://开头" />
        <el-button type="primary" @click="addoriginalurl()">添加长链</el-button>
    </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus'

export default {
    name:'AddOriginal',
    data(){
        return{
            originalinput:''
        }
    },
    props: ['shortCode', 'readshortlinks'],
    methods:{
        addoriginalurl(){
            if(!this.isValidURL(this.originalinput)){
                ElMessage({
                    message: '请输入正确的url',
                    type: 'error',
                })
            }else{
                axios.post("https://api.cxkqun.com/index.php/api/short/long",{
                    'auth':'cxkqun',
                    'action':'create',
                    'shortCode':this.shortCode,
                    'originalUrl':this.originalinput
                }).then(res => {
                    if(res.data.code){
                        ElMessage({
                            message: '长链添加成功',
                            type: 'success',
                        })
                        this.readshortlinks()
                    }else{
                        ElMessage({
                            message: '长链添加失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        // url格式检查
        isValidURL(string) {
            let url;
            try {
                url = new URL(string);
            } catch (_) {
                return false;
            }
            return url.protocol === "http:" || url.protocol === "https:";
        }
    }

}
</script>

<style>

</style>