<template>
  <div class="cxkqun">
    <!-- 短链卡片 -->
    <div class="shortlinks" v-for="(shortitem, index) of short" :key="shortitem.id">
        <!-- 短链信息与操作 -->
        <div class="short-card">
            <!-- 左边显示 -->
            <div class="left">
                <div class="index">{{ "#" + (index + 1) }}</div>
                <div class="data">{{"https://cxkqun.xyz/" + shortitem.short_code}}</div>
            </div>
            <!-- 右边显示 -->
            <div class="right">
                <el-popconfirm title="确定删除短链吗?" @confirm="deleteshortlink(shortitem.short_code)">
                    <template #reference>
                        <el-button type="danger">删除短链</el-button>
                    </template>
                </el-popconfirm>
            </div>
        </div>
        <!-- 长链信息与操作 -->
        <div class="original-card">
            <!-- 头部 -->
            <div class="header">
                <!-- 左边 -->
                <div class="left">
                    <div class="index">索引</div>
                    <div class="data">长链</div>
                </div>
                <!-- 右边 -->
                <div class="right">
                    <div class="note">备注</div>
                    <div class="count">访问量</div>
                    <div class="action">操作按钮</div>
                </div>
            </div>
            <!-- 数据展示 -->
            <ShowOriginalVue v-for="(long, index) of shortitem.long" :key="long.id" :index="index" :long="long" :readshortlinks="readshortlinks"></ShowOriginalVue>
            <!-- 添加长链 -->
            <AddOriginal :shortCode="shortitem.short_code" :readshortlinks="readshortlinks"></AddOriginal>
        </div>
    </div>

    <!-- 添加短链 -->
    <div class="add-shortlink">
        <el-button type="primary" @click="addshortlink()">添加短链</el-button>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { ElMessage } from 'element-plus'

// 添加长链组件
import AddOriginal from './AddOriginal.vue';
import ShowOriginalVue from './ShowOriginal.vue';

export default {
    data(){
        return{
            short:[]
        }
    },
    components:{
        AddOriginal,
        ShowOriginalVue
    },
    methods:{
        // 添加短链
        addshortlink(){
            axios.post("https://api.cxkqun.com/index.php/api/short/short",{
                'auth':'cxkqun',
                'action':'create'
            }).then(res => {
                if(res.data.code){
                    ElMessage({
                        message: '短链添加成功',
                        type: 'success',
                    })
                    this.readshortlinks()
                }
            })
        },
        // 查找短链
        readshortlinks(){
            axios.post("https://api.cxkqun.com/index.php/api/short/long",{
                'auth':'cxkqun',
                'action':'read'
            }).then(res => {
                this.short = res.data.data
            })
        },
        // 删除短链
        deleteshortlink(short_code){
            axios.post("https://api.cxkqun.com/index.php/api/short/short",{
                'auth':'cxkqun',
                'action':'delete',
                'shortCode':short_code
            }).then(res => {
                if(res.data.code){
                    ElMessage({
                        message: '短链删除成功',
                        type: 'success',
                    })
                    this.readshortlinks()
                }else{
                    ElMessage({
                        message: '短链删除失败',
                        type: 'error',
                    })
                }
            })
        }
    },
    mounted(){
        this.readshortlinks();
    }
}
</script>

<style scoped lang="less">
    .cxkqun{
        padding: 10px;
        // width: 1000px;
        height: 100vh;
        overflow-y: auto;
        background-color: #f4f4f5;
        // 短链卡片
        &>.shortlinks{
            background-color: #fff;
            width: 100%;
            margin-bottom: 10px;
            border: solid #b1b3b8 1px;
            border-radius: 2px;
            color: #606266;

            // 短链信息
            &>.short-card{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px;

                // 左边
                &>.left{
                    display: flex;
                    &>.index{
                        width: 60px;
                        text-align: center;
                    }
                }
                // 右边
                &>.right{
                    display: flex;
                    
                }
            }

            &>.original-card{
                border: solid #b1b3b8 1px;
                margin: 0 10px 10px 10px;
                border-radius: 2px;

                // 头部
                &>.header{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    &>.left{
                        display: flex;
                        &>.index{
                            width: 60px;
                            text-align: left;
                            padding: 0 10px 0 10px;
                            line-height: 30px;
                        }
                        &>.data{
                            line-height: 30px;
                            padding: 0 10px;
                        }
                    }
                    &>.right{
                        display: flex;
                        &>.note{
                            width: 300px;
                            text-align: left;
                        }
                        &>.count{
                            width: 60px;
                            text-align: left;
                        }
                        &>.action{
                            width: 150px;
                            text-align: center;
                        }
                    }
                }

                

                // 添加长链
                .add-original{
                    display: flex;
                    border-top: solid #b1b3b8 1px;
                    justify-content: space-between;
                    padding: 10px;
                }
            }
        }

        &>.add-shortlink{
            background-color: #fff;
            width: 100%;
            margin-bottom: 10px;
            border: solid #b1b3b8 1px;
            border-radius: 2px;
            color: #606266;
            padding: 10px;
            display: flex;
            justify-content: right;
        }
    }
</style>